export default {
  props: {
    gridColumns: {
      type: String,
      required: false,
      default: 'col-span-4',
    },
    gridColumnsDesktop: {
      type: String,
      required: false,
      default: 'lg:col-span-12',
    },
    gridColumnEnd: {
      type: String,
      required: false,
      default: 'col-end-4',
    },
    gridColumnEndDesktop: {
      type: String,
      required: false,
      default: 'lg:col-end-12',
    },
  },
}
