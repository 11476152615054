<template>
  <div class="flex items-center gap-4 p-3 lg:gap-10 lg:p-6 bg-lightGray">
    <div class="flex flex-col justify-between gap-4">
      <div class="flex flex-col gap-2 max-w-[200px]">
        <span class="text-sm font-semibold tracking-wider lg:text-base">{{
          name
        }}</span>
        <div class="flex text-sm tracking-wider text-left lg:text-base">
          <span
            v-if="product.discounted"
            class="mr-2 tracking-wider text-brandRed"
            >{{ product.price }}</span
          >
          <span v-else class="tracking-wider">{{ product.price }}</span>

          <span
            v-if="product.discounted"
            class="tracking-wider line-through text-darkGray"
            >{{ product.priceBeforeDiscount }}</span
          >
        </div>
      </div>
      <mp-link
        class="
          text-sm
          tracking-wider
          underline
          text-brandRed
          underline-offset-4
          lg:text-base
          hover:opacity-60
        "
        :to="$u(url)"
        >{{ $t('Shop_Now') }}</mp-link
      >
    </div>
    <Vue2Image
      :src="image"
      :alt="name"
      :widths="[80]"
      :quality="60"
      background="f0f0f0"
      class="object-contain h-auto w-14 lg:w-20"
    />
  </div>
</template>
<script>
import Vue2Image from '@made-people/vue2-image'
export default {
  name: 'HotspotCard',
  components: {
    Vue2Image,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
}
</script>
