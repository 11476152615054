<template>
  <mp-link :to="$u(link)" :class="[color]" aria-label="Link button">
    <Button
      v-if="button"
      :bg="buttonBg"
      :color="color"
      class="tracking-wider uppercase"
      font-size="text-sm lg:text-lg"
      px="px-6 lg:px-8"
      py="py-3 lg:py-4"
      font-weight="font-normal"
    >
      {{ label }} <template v-if="arrow">→</template>
    </Button>
    <span
      v-else
      class="
        tracking-wider
        transition-colors
        cursor-pointer
        underline-offset-8
        hover:text-brandRed
      "
      :class="{ underline: underline }"
      >{{ label }} <template v-if="arrow">→</template></span
    >
  </mp-link>
</template>

<script>
export default {
  name: 'Link',
  props: {
    link: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'text-black',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    underline: {
      type: Boolean,
      required: false,
      default: false,
    },
    arrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    button: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonBg: {
      type: String,
      required: false,
      default: 'bg-lightGray',
    },
  },
}
</script>
